import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Layout from '../components/layout'
import SEO from '../components/seo'

import Slider from '../components/slider'

export const SalesQuery = graphql`
  query Sales {
    prismicSales {
      data {
        sales_infos {
          html
          text
        }
        sales_pdf_email_to {
          url
        }
        sales_gallery {
          image_item {
            localFile {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

const SalesPage = ({ data }) => {
  const salesGallery = data.prismicSales.data.sales_gallery

  const textSalesAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 600,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const sliderSalesAnim = useSpring({
    from: { opacity: 0.5 },
    to: { opacity: 1 },
    delay: 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Layout>
      <SEO
        title="Sales"
        keywords={[
          `Art`,
          `for`,
          `sale`,
          `art for sale`,
          `Jamie`,
          `design`,
          `sculpture`,
          `collage`,
          `drawing`,
          `piece of art`
        ]}
      />
      <Container>
        <SEO title="Sales" keywords={[`Shop`, `Art to buy`]} />
        <WrapSales style={sliderSalesAnim}>
          <a
            href={`${data.prismicSales.data.sales_pdf_email_to.url}?subject=Hello%20Jamie%20please%20send%20a%20pdf%20of%20works%20available`}
          >
            <Slider salesGallery={salesGallery} />
          </a>
        </WrapSales>
        <InfosSales>
          <a
            href={`${data.prismicSales.data.sales_pdf_email_to.url}?subject=Hello%20Jamie%20please%20send%20a%20pdf%20of%20works%20available`}
          >
            <animated.p style={textSalesAnim}>{data.prismicSales.data.sales_infos.text}</animated.p>
          </a>
        </InfosSales>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 0px 1.0875rem;
  padding-top: 0;
  z-index: 1000;
`

const WrapSales = styled(animated.section)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 1000;

  @media screen and (max-width: 500px) {
    width: 100%;
    max-height: 100%;
  }
`

const InfosSales = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  width: 100%;
  max-width: calc(100% - 50px);
  margin: 0 auto;
  transform: translateX(-50%);
  z-index: 11;

  @media screen and (min-width: 768px) {
    max-width: 400px;
  }

  a {
    color: #000;
    font-weight: 300;
  }

  p {
    font-size: 14px;
    text-align: center;
    font-weight: 300;
  }
`

export default SalesPage
