import React from 'react'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import styled from 'styled-components'

const TheSlider = styled(Slider)`
  width: 300px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  @media screen and (min-width: 500px) {
    max-width: 650px;
    max-height: 400px;
  }

  .slick-slide {
    @media screen and (max-width: 420px) {
      width: 420px !important;
      max-width: 420px;
    }

    @media screen and (max-width: 411px) {
      width: 411px !important;
      max-width: 411px;
    }

    @media screen and (max-width: 375px) {
      width: 375px !important;
      max-width: 375px;
    }

    @media screen and (max-width: 360px) {
      width: 360px !important;
      max-width: 360px;
    }

    @media screen and (max-width: 320px) {
      width: 320px !important;
      max-width: 320px;
    }

    @media screen and (min-width: 769px) {
      width: 650px !important;
      max-width: 650px;
    }
  }
`

const ImageItem = styled(Img)`
  width: 300px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 500px) {
    width: 100%;
    height: 100%;
    max-width: 650px;
    max-height: 425px;
    margin: 0 auto;
    background-color: white;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    object-fit: contain !important;
    /* object-position: 50% 50%; */
    margin-bottom: 0;
  }
`

const settings = {
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 300,
  autoplaySpeed: 2500,
  arrows: false,
  pauseOnHover: true
}

export default function Image({ salesGallery }) {
  return (
    <TheSlider {...settings}>
      {salesGallery.map((galleryItem, index) => (
        <ImageItem
          key={index}
          fluid={galleryItem.image_item.localFile.childImageSharp.fluid}
          fadeIn
        />
      ))}
    </TheSlider>
  )
}
